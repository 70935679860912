


























































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

interface reservaData {
  rut: string;
  fecha_retiro: string | null;
  nombre: string;
  mesa: string | null;
  estado: number;
  observacion: string | null;
  horario: string | null;
}

@Component
export default class ResultadoFinalComponent extends Vue {
  @Prop({ required: true })
  private errorType!: string;
  @Prop({ required: true })
  private nombre_apoderado!: string;
  @Prop({ required: true })
  private listaAlumnos!: Array<reservaData>;

  private aprobado = false;
  private fecha_retiro: string | null = "";
  private hora_retiro: string | null = "";
  private mesa_retiro: string | null = "";

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }

  private mounted() {
    for (let i = 0; i < this.listaAlumnos.length; i++) {
      if (this.listaAlumnos[i].estado == 1) {
        this.aprobado = true;
        // if (this.listaAlumnos[i].fecha_retiro) {
        //   this.fecha_retiro =
        //     (this.listaAlumnos[i].fecha_retiro as any).substring(8, 10) +
        //     "-" +
        //     (this.listaAlumnos[i].fecha_retiro as any).substring(5, 7) +
        //     "-" +
        //     (this.listaAlumnos[i].fecha_retiro as any).substring(0, 4);
        // }
        this.fecha_retiro = this.listaAlumnos[i].fecha_retiro;
        this.hora_retiro = this.listaAlumnos[i].horario;

        if (this.listaAlumnos[i].mesa) {
          this.mesa_retiro = this.listaAlumnos[i].mesa;
        }
      }
    }
    // console.log(this.listaAlumnos);
  }
}
